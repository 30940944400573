@import url('../../styles/colors.scss');

.cookie {
  position: fixed;
  bottom: 32px;
  max-width: 800px;
  right: 100px;
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  border: 2px solid var(--Medium-blue);
  background-color: var(--Dark-blue);

  .text {
    color: var(--Gray, #A7AAB0);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  button {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Black, #020202);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 126px;
    background: var(--White, #FFF);
    cursor: pointer;
    white-space: pre;
  }
}

@media screen and (max-width: 1310px) {
  .cookie {
    right: auto;
    margin: 0 32px;
    bottom: 0;
    width: calc(100% - 64px);
    max-width: none;
  }
}

@media screen and (max-width: 768px) {
  .cookie {
    width: calc(100% - 64px);
    margin: 0 32px;
    bottom: 0;
    right: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .text {
      font-size: 12px;
      line-height: 18px;
    }

    button {
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}